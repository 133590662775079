import React from 'react'
import { graphql } from 'gatsby'
import _get from 'lodash/get'

import Layout from '../components/Layout'
import SEO from '../components/SEO'

function NotFoundPage({ location, data }) {
  const siteTitle = _get(data, 'site.siteMetadata.title')
  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="404: Not Found" />
      <h1>Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
